
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import LoginPage from "../views/auth/Login.vue";
import CheckGoogle2FaCode from "../views/auth/CheckGoogle2FaCode.vue";
import NotFound from "../views/NotFound.vue";

import Dashboard from "@/views/admin/Dashboard.vue"

import * as Admin from "@/views/admin/catalogue"
import * as Product from "@/views/admin/product"
import * as Supplier from "@/views/admin/supplier";
import * as Category from "@/views/admin/category";
import * as Transporter from "@/views/admin/transporter";
import * as CustomProperties from "@/views/admin/custom_properties";
import * as CustomAttribute from "@/views/admin/custom_attribute";
import * as Document from  "@/views/admin/document";
import * as Code from  "@/views/admin/code";
import * as Filter from  "@/views/admin/filter";
import * as VariantProductCollection from  "@/views/admin/variantProductCollection"
import AdminLayout from "@/views/admin/Layout.vue"
import * as MegaMenuCategories from "@/views/admin/mega_menu_categories";
import * as Homepage from "@/views/admin/homepage";
import * as Jackpot from "@/views/admin/jackpot";
import * as Securite from "@/views/admin/securite";
import * as Ogloba from "@/views/admin/ogloba";
import * as monitoringOglobaOrder from "@/views/admin/monitoringOglobaOrder";

import { authGuard } from "@/_helpers/auth-guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
    props: true,
    meta: { layout: "empty" },
  },
  {
    path: "/verify/2fa",
    name: "CheckGoogle2FaCode",
    component: CheckGoogle2FaCode,
    props: true,
    meta: { layout: "empty" },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminLayout,
    children :  [ 
      {path: "dashboard",name: "dashboard",  component: Dashboard },
      {path: "catalogue/listCategories/:uuid?",name: "listCategories",  component:Admin.ListCategories },
      {path: "catalogue/category/create:uuid?",name: "addCategory",  component: Category.AddCategoryForm },
      {path: "catalogue/category/edit/:uuid",name: "editCategory",  component: Category.EditCategoryForm },
      {path: "category/treeview", name: "categoryTreeview",  component:Category.CategoriesTreeView },
      {path: "company/category/settings/:uuid", name: "companyCategorySettings",  component:Category.CompanyCategorySettings },
      {path: "catalogue/category/product/list", name: "CategoryProductList", component: Admin.CategoryProductList },
      {path: "catalogue/ListCodes",name: "ListCodes",  component: Admin.ListCodes },
      {path: "catalogue/ListDocuments",name: "ListDocuments",  component: Admin.ListDocuments },
      {path: "catalogue/ListProducts",name: "ListProducts",  component: Admin.ListProducts },
      {path: "catalogue/product/variants/:uuid",name: "listProductVariants",  component: Product.ListProductVariants },
      {path: "addProduct",name: "addProduct",  component:  Product.PageProduct},
      {path: "catalogue/ListTags",name: "ListTags",  component: Admin.ListTags },
      {path: "catalogue/ListUrssaf",name: "ListUrssaf",  component: Admin.ListUrssaf },
      {path: "catalogue/liste-des-categories-de-produit-filtrables", name: "filterableCategoryList", component: Filter.FilterableCategoryList },
      {path: "catalogue/gestion-des-filtres-de-categorie-produit/:uuid", name: "categoryProductFiltersManagement", component: Filter.CategoryFiltersList },
      {path: "catalogue/:uuid/ajouter-filtre-categorie-de-produit", name: "addCategoryFilter",  component: Filter.AddCategoryFilterForm },
      {path: "addProduct",name: "addProduct",  component:  Product.PageProduct},
      {path: "product/:uuid/edit",name: "EditProduct",  component:  Product.PageProductEdition},
      {path: "product/variant/:uuid/edit",name: "EditVariantProduct",  component:  Product.PageVariantProductEdition},
      {path: "product/variant/property/value/:uuid", name: "listVariantProductsOfPropertyValue", component: Product.ListProductVariantsOfPropertyValue},
      {path: "add/variant/product/property/:propertyUuid/value/:uuid", name: "addVariantProductToPropertyValue", component: Product.AddVariantProductToPropertyValue},
      {path: "supplier/shippingMethod/:uuid", name: "shippingMethod",  component:  Supplier.ListShippingMethod},
      {path: "supplier/shippingMethod/:uuid/add", name: "addShippingMethod",  component:  Supplier.AddShippingMethod},
      {path: "supplier/shippingMethod/:uuid/edit", name: "editShippingMethod",  component:  Supplier.EditShippingMethod},
      {path: "suppliers/list", name: "suppliersList",  component: Supplier.SupplierList },
      {path: "suppliers/:uuid/:ariaSelected?", name: "supplierDetail",  component: Supplier.SupplierDetail },
      {path: "back-office-supplier-user-list/:uuid", name: "backOfficeSupplierList",  component: Supplier.BackOfficeSupplierList },
      {path: "catalogue/transporter/list", name: "transporterList",  component: Transporter.TransporterList },
      {path: "properties/create", name: "customPropertiesCreate",  component: CustomProperties.CustomPropertiesCreate },
      {path: "properties/list", name: "customPropertiesList",  component: CustomProperties.CustomPropertiesList },
      {path: "properties/:uuid", name: "customPropertiesEdit",  component:CustomProperties.CustomPropertiesEdit },
      {path: "property/:uuid/values", name: "CustomPropertyValuesList", component:CustomProperties.CustomPropertyValuesList },
      {path: "catalogue/transporter/list", name: "transporterList",  component: Transporter.TransporterList },
      {path: "addDocument",name: "addDocument",  component:  Document.PageDocument},
      {path: "soldExport", name: "soldExport", component: Document.SoldExport},
      {path: "catalogue/code/create",name: "addCode",  component:  Code.CodeCreate},
      {path: "addAttribut",name: "addAttribut",  component:  CustomAttribute.CustomAttribut},
      {path: "addAttribut/:uuid",name: "customAddAttribut",  component:  CustomAttribute.CustomAttribut},
      {path: "catalogue/listVariantProductCollection", name: "listVariantProductCollection",  component:  Admin.ListVariantProductCollection},
      {path: "catalogue/variantProductCollection/create",name: "addVariantProductCollection", component: VariantProductCollection.AddVariantProductCollection },
      {path: "catalogue/variantProductCollection/edit/:uuid",name: "editVariantProductCollection", component: VariantProductCollection.EditVariantProductCollection },
      {path: "catalogue/listMegaMenusCategories", name: "listMegaMenusCategories",  component:  MegaMenuCategories.MegaMenuCategoriesList},
      {path: "catalogue/megaMenusCategories/add", name: "addMegaMenusCategories",  component:  MegaMenuCategories.MegaMenuCategoriesAdd},
      {path: "catalogue/megaMenusCategories/edit/:uuid",name: "editMegaMenusCategories", component: MegaMenuCategories.MegaMenuCategoriesEdit },
      {path: "homepage/news", name: "newsList",  component: Homepage.ListNews },
      {path: "homepage/news/:uuid/edit", name: "editNews",  component:  Homepage.EditNews},
      {path: "homepage/news/add", name: "addNews",  component:  Homepage.AddNews},
      {path: "homepage/carousel", name: "carouselList",  component: Homepage.ListCarousel },
      {path: "homepage/carousel/:uuid/edit", name: "editCarousel",  component:  Homepage.EditCarousel},
      {path: "homepage/carousel/add", name: "addCarousel",  component:  Homepage.AddCarousel},
      {path: "homepage/middle-menu", name: "middleMenuList",  component:  Homepage.MiddleMenuList},
      {path: "homepage/middle-menu/add", name: "addMiddleMenu",  component:  Homepage.AddMiddleMenu},
      {path: "homepage/middle-menu/:uuid/edit", name: "editMiddleMenu",  component:  Homepage.EditMiddleMenu},
      {path: "homepage/middle-menu/:uuid/item", name: "middleMenuItemList",  component:  Homepage.MiddleMenuItemList},
      {path: "homepage/middle-menu-item/:uuid/add", name: "addMiddleMenuItem",  component:  Homepage.AddMiddleMenuItem},
      {path: "homepage/middle-menu-item/:uuid/edit", name: "editMiddleMenuItem",  component:  Homepage.EditMiddleMenuItem},
      {path: "catalogue/gift-card-product-api", name: "jackpot", component: Jackpot.PageJackpot},
      {path: "catalogue/gift-card-product-api/:uuid/edit", name: "editJackpot", component: Jackpot.EditPageJackpot},
      {path: "catalogue/create-ogloba-order", name: "createOglobaOrder", component: Ogloba.CreateOglobaOrder},
      {path: "catalogue/list-ogloba-order", name: "listOglobaOrder", component: Ogloba.ListOglobaOrder},
      {path: "catalogue/gift-card-product-api", name: "jackpot", component: Jackpot.PageJackpot},
      {path: "white/list", name: "whitelist", component: Securite.WhiteList2fa},
      {path: "enable/2fa/strong/authentication", name: "enable2FaStrongAuthentication", component: Securite.StrongAuthentication},
      {path: "catalogue/monitoring-ogloba-order", name: "monitoringOglobaOrder", component: monitoringOglobaOrder.MonitoringOglobaOrder},
      {path: "hideProduct", name: "hideProduct",  component:  Product.PageProductHide},
      {path: "hideProductListing", name: "hideProductListing",  component:  Product.ListingPageProductHide},
    ]},
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to , from, next ) =>{
  if(to.matched[0].name == 'admin'){
    authGuard(to,from, next);
  }
  next();
})

export default router;
